<template>
  <PageContainer
    v-if="pageToc?.links?.length"
    :id="componentId"
    :background="background"
    :background-color="backgroundColor"
    :border="border"
    :border-radius="borderRadius"
    class="page-toc"
    :color="color"
    data-testid="page-toc"
    display="flex"
    flex-direction="row"
    :margin="margin"
    :padding="padding"
    :tag="getMdcTag('div')"
  >
    <nav class="page-toc-nav">
      <div class="page-toc-header">
        <div
          v-if="title"
          class="page-toc-title"
          data-testid="page-toc-title"
        >
          {{ title }}
        </div>
      </div>
      <ol
        ref="pageTocList"
        data-testid="page-toc-list"
      >
        <PageTocItem
          v-for="link in pageToc.links"
          :key="link.id"
          :active-link-id="activeLinkId"
          :link="link"
          :max-depth="tocDepth"
        />
      </ol>
    </nav>
  </PageContainer>
</template>

<script setup lang="ts">
import { KUI_SPACE_0, KUI_SPACE_70 } from '@kong/design-tokens'
import type { PageTocProps } from '#imports'
import type { Toc } from '@nuxtjs/mdc'

const {
  title = 'On this page',
  depth = 2,
  background = undefined,
  backgroundColor = 'initial',
  border = 'initial',
  borderRadius = 'initial',
  color = 'initial',
  margin = `var(--kui-space-0, ${KUI_SPACE_0})`,
  padding = `var(--kui-space-70, ${KUI_SPACE_70})`,
  styles = '',
} = defineProps<PageTocProps>()

// Inject any custom `props.styles` scoped by the `componentId` into the document head
const { componentId } = useCustomStyles(computed(() => styles), useAttrs().id as string)

/**
 * Determine the depth of headings to display in the TOC.
 * The depth is set to a default value of 2 if not provided.
 *
 * @example A depth of 2 renders both level 2 and level 3 headings.
 */
const tocDepth = computed((): number => {
  const defaultDepth = 2
  // If NaN, set to defaultDepth, otherwise parse the depth and add 1 to start from level 2 headings
  const _depth = isNaN(depth) ? defaultDepth : Number(depth) + 1

  // These are min/max for the evaluated depth, not the depth prop
  const _minDepth = 1
  const _maxDepth = 6
  return Math.min(Math.max(_depth, _minDepth), _maxDepth)
})

const route = useRoute()
const pageToc = inject(PageTocInjectionKey, computed(() => {
  /**
   * Check if we are on the `/_preview-mode/snippets/[snippet_name]` route.
   *
   * If yes, initialize mocked TOC data for preview mode.
   * If no, initialize as undefined.
   */
  if (route.path.startsWith('/_preview-mode/snippets/')) {
    return {
      depth: 5,
      searchDepth: 5,
      title: 'On this page',
      links: [
        // Heading 2 (3 count)
        { id: 'heading-2-1', text: 'Heading 2-1', depth: 2 },
        { id: 'heading-2-2', text: 'Heading 2-2', depth: 2 },
        { id: 'heading-2-3', text: 'Heading 2-3', depth: 2, children: [
          // Heading 3 (2 count)
          { id: 'heading-3-1', text: 'Heading 3-1', depth: 3 },
          { id: 'heading-3-2', text: 'Heading 3-1', depth: 3, children: [
            // Heading 4 (1 count)
            { id: 'heading-4-1', text: 'Heading 4-1', depth: 4, children: [
              // Heading 5 (2 count)
              { id: 'heading-5-1', text: 'Heading 5-1', depth: 5 },
              { id: 'heading-5-2', text: 'Heading 5-2', depth: 5, children: [
                // Heading 6 (1 count)
                { id: 'heading-6-1', text: 'Heading 6-1', depth: 6 },
              ] },
            ] },
          ] },
        ] },
      ],
    } satisfies Toc
  }

  return undefined
}))

const pageTocList = useTemplateRef('pageTocList')
const { activeLinkId, refresh } = usePageToc(pageTocList, tocDepth.value)

// !Important: When the provided links change, refresh the active link observer
watch(() => pageToc.value?.links, async () => {
  await refresh()
}, { deep: true })

onMounted(async () => {
  await refresh()
})
</script>

<style lang="scss" scoped>
.page-toc-nav {
  width: 100%;

  @media (min-width: $kui-breakpoint-laptop) {
    padding-left: var(--kui-space-0, $kui-space-0);
    padding-top: var(--kui-space-0, $kui-space-0);
  }
}

.page-toc-header {
  align-items: flex-start;
  // display: none;
  display: flex;
  gap: var(--kui-space-20, $kui-space-20);
  justify-content: space-between;
  width: 100%;

  @media (min-width: $kui-breakpoint-tablet) {
    display: flex;
  }
}

.page-toc-title {
  color: var(--kui-color-text-neutral-stronger, $kui-color-text-neutral-stronger);
  font-family: var(--kui-font-family-text, $kui-font-family-text);
  font-size: var(--kui-font-size-30, $kui-font-size-30);
  font-weight: var(--kui-font-weight-semibold, $kui-font-weight-semibold);
  line-height: var(--kui-line-height-30, $kui-line-height-30);
  padding-bottom: var(--kui-space-30, $kui-space-30);
  user-select: none;
  white-space: nowrap;
}

.close-button {
  align-items: center;
  background-color: var(--kui-color-background-transparent, $kui-color-background-transparent);
  border: none;
  color: var(--kui-color-text-neutral-weak, $kui-color-text-neutral-weak);
  cursor: pointer;
  display: inline-flex;
  gap: var(--kui-space-20, $kui-space-20);
  justify-content: center;
  justify-self: flex-end;
  padding: var(--kui-space-0, $kui-space-0);

  @media (min-width: $kui-breakpoint-tablet) {
    display: none;
  }
}

ol,
:deep(ol) {
  list-style: none;
  margin: var(--kui-space-0, $kui-space-0);
  padding: var(--kui-space-0, $kui-space-0);
  width: 100%;
}

ol li :deep(ol) {
  margin-left: var(--kui-space-80, $kui-space-80);
}
</style>
